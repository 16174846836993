export default {
  data () {
    return {
      templateTypes: [
        {
          label: 'Proposition d\'entretien',
          value: 'application:meeting',
          icon: 'uil:schedule',
          color: 'positive',
          chipClass: 'bg-positive text-white text-caption q-pa-sm'
        },
        {
          label: 'Candidature refusée',
          value: 'application:refused',
          icon: 'uil:times',
          color: 'negative',
          chipClass: 'bg-negative text-white text-caption q-pa-sm'
        },
        {
          label: 'Message',
          value: 'message:custom',
          icon: 'uil:envelope-alt',
          color: 'primary',
          chipClass: 'bg-dark text-white text-caption q-pa-sm'
        }
      ],
      variables: [
        { type: 'applicant', slug: 'email-candidat', value: 'email' },
        { type: 'applicant', slug: 'nom-candidat', value: 'lastname' },
        { type: 'applicant', slug: 'prenom-candidat', value: 'firstname' },
        { type: 'applicant', slug: 'tel-candidat', value: 'metadata._private.phone' },
        { type: 'offer', slug: 'nom-offre', value: 'name' },
        { type: 'offer', slug: 'ville-offre', value: 'locations[0].city' },
        { type: 'offer', slug: 'cp-offre', value: 'locations[0].postcode' },
        { type: 'offer', slug: 'adresse-offre', value: 'locations[0].street' },
        { type: 'meeting', slug: 'date-entretien', value: 'date' },
        { type: 'meeting', slug: 'heure-entretien', value: 'time' },
        { type: 'company', slug: 'nom-entreprise', value: 'name' },
        { type: 'company', slug: 'email-entreprise', value: 'email' },
        { type: 'company', slug: 'tel-entreprise', value: 'metadata._private.phone' },
        { type: 'location', slug: 'ville', value: 'city' },
        { type: 'location', slug: 'code-postal', value: 'postcode' },
        { type: 'location', slug: 'adresse', value: 'street' },
        // Campaign
        { type: 'campaign', slug: 'label-campagne', value: 'data.label' },
        { type: 'campaign', slug: 'contexte-campagne', value: 'data.context' },
        { type: 'campaign', slug: 'prenom-createur-campagne', value: 'metadata.sender.firstname' },
        { type: 'campaign', slug: 'nom-createur-campagne', value: 'metadata.sender.lastname' },
        { type: 'campaign', slug: 'job-createur-campagne', value: 'metadata.sender.job' },
      ],
    }
  },
  computed: {
    computedVariables () {
      return [
        {
          icon: 'uil:user-circle',
          label: 'Candidat',
          caption: 'Nom, prénom, email, téléphone',
          variables: this.variables.filter(({ type }) => type === 'applicant')
        },
        {
          icon: 'uil:file',
          label: 'Offre d\'emploi',
          caption: 'Nom du poste, adresse, etc.',
          variables: this.variables.filter(({ type }) => type === 'offer')
        },
        {
          icon: 'uil:schedule',
          label: 'Entretiens',
          caption: 'Nom du poste, adresse, etc.',
          variables: this.variables.filter(({ type }) => type === 'meeting')
        },
        {
          icon: 'uil:building',
          label: 'Entreprise',
          caption: 'Nom, email, téléphone',
          variables: this.variables.filter(({ type }) => type === 'company')
        }
      ]
    }
  },
  methods: {
    buildTemplate (content, entities) {
      if (!content) return content
      for (const variable of this.variables) {
        if (variable.type === 'locations' && entities.locations) {
          let index = 1
          for (const location of entities.locations) {
            if (content.includes(`{${variable.slug}_${index}}`)) {
              content = this.$_.replace(content, new RegExp(`{${variable.slug}_${index}}`, 'g'), this.$_.get(location, variable.value, ''))
            }
            index++
          }
        } else if (content.includes('{' + variable.slug + '}') && entities[variable.type]) {
          content = this.$_.replace(content, new RegExp(`{${variable.slug}}`, 'g'), this.$_.get(entities[variable.type], variable.value), '')
        }
      }
      return content
    },
    async processAllApplicantsOfOffer ({ asset = undefined, statuses = undefined, transitionName = undefined, template = undefined, applications = undefined }) {
      if (!asset || (!statuses || !applications) || !transitionName || !template) return null
      const conversationsById = this.$store.getters['inbox/inbox'].conversationsById

      // DO NOT rely on this.$uElements('applications'), since it could be outdated (after moving one application in kanban for example, since it does not fetch userElements after updating transaction status)
      const applicationsLoop = applications ?? this.$uElements('applications').filter(a => statuses.includes(a.status) && a.assetId === asset.id)

      for (const application of applicationsLoop) {
        await this.$store.dispatch('asset/createTransactionTransition', { transaction: application, transitionName })
        await this.$store.dispatch('inbox/sendMessage', {
          conversationId: this.$_.findKey(conversationsById, { interlocutorId: this.$_.get(application, 'takerId', false) }) ?? undefined,
          topicId: this.$store.getters['inbox/talkAssets'].userTopicId,
          attachments: [{ type: 'transaction', id: application.id }],
          content: this.buildTemplate(template.content, {
            applicant: this.$uElements('applicants').find(a => a.id === application.takerId),
            offer: asset,
            company: this.userCompany,
            locations: this.userCompany.locations
          }),
          senderId: this.currentUser.id,
          receiverId: application.takerId,
          // Inspired from https://stackoverflow.com/a/25334277 - adding 6 hours
          metadata: { scheduledToSend: true, scheduledTimestamp: new Date(new Date().getTime() + 6 * 3600000).toISOString() }
        })
      }
      await this.$store.dispatch('auth/getUserOrganizations', { fields: ['inbox'] })
    }
  }
}
