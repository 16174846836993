<template>
  <div ref="FilesViewer">
    <QTabs
      v-if="mappedFiles.length > 1"
      v-model="tab"
      inline-label
      outside-arrows
      mobile-arrows
      active-color="secondary"
    >
      <QTab
        v-for="(file, i) of mappedFiles"
        :key="i"
        :name="i"
        :icon="icons[file.type]"
        :label="file.label ? $t({ id: file.label }) : 'Fichier'"
        :class="file.type === 'pdf' ? 'text-secondary' : 'text-grey'"
      >
        <HCButton
          v-if="file.type === 'pdf' && tab === i"
          icon="uil:import"
          class="q-ml-sm cursor-pointer text-positive"
          tooltip="prompt.dl_button"
          @click="dlKeyFromS3({ key: file.key, type: file.type, dlLabel: $_.get(file, 'dlLabel', undefined)})"
        />
        <HCButton
          v-if="file.type === 'pdf' && tab === i && file.allowRemove"
          icon="uil:trash"
          class="q-ml-sm cursor-pointer text-negative"
          tooltip="prompt.delete_button"
          @click="confirmRemove(file)"
        />
      </QTab>
    </QTabs>
    <QTabPanels v-model="tab" animated class="fit">
      <QTabPanel v-for="(file, i) of mappedFiles" :key="i" :name="i" class="full-width q-pa-none" style="height:98%">
        <iframe
          v-if="file.type === 'pdf'"
          :src="file.src"
          type="application/pdf"
          frameborder="0"
          class="fit"
        />
        <QImg
          v-if="file.type === 'image'"
          :src="file.src"
          class="fit"
        />
        <FileUploader
          v-if="file.type === 'upload'"
          class="q-mx-auto"
          :upload-config="file.uploadConfig"
          @uploaded="$emit('uploaded', $event)"
        />
        <div
          v-if="file.type === 'expiredResume'"
          class="full-width row flex-center q-pa-md q-gutter-sm text-grey-5 "
        >
          <QIcon size="2em" name="uil:confused" />
          <AppContent
            class="text-italic"
            path="profile.resume.expired_hint"
          />
        </div>
      </QTabPanel>
    </QTabPanels>
    <HCLoading :showing="loading" inner />
  </div>
</template>

<script>
import { dlKeyFromS3, getEmbedFileSrc } from 'hc-core/composables/aws.js'
import FileUploader from 'hc-core/components/files/file-uploader'

export default {
  components: { FileUploader },
  // mixins: [AWSMixins],
  props: {
    files: {
      type: Array,
      default: () => []
      // [{ type: 'pdf', label, key }, { type: 'image', label, key }, { type: 'upload', label, uploadConfig }]
    },
  },
  emits: ['removed', 'uploaded'],
  data () {
    return {
      tab: 0,
      loading: true,
      mappedFiles: [],
      icons: {
        pdf: 'uil:file-alt',
        image: 'uil:scenery',
        upload: 'uil:export',
        expiredResume: 'uil:file-times'
      },
    }
  },
  async mounted () {
    const first = this.$_.get(this.files, '[0]', null)
    if (first) this.tab = 0
    await this.mapFiles()
    this.loading = false
  },
  beforeUnmount () {
    // Try to clean eventually created blobs
    try {
      for (const mappedFile of this.mappedFiles) {
        if (this.$_.get(mappedFile, 'src', '').startsWith('blob:')) URL.revokeObjectURL(mappedFile.src)
      }
    } catch (e) { }
  },
  methods: {
    dlKeyFromS3,
    async mapFiles () {
      const mappedFilesPromises = this.files.map(async (f) => {
        if (f.type === 'pdf') return this.$_.set(f, 'src', await getEmbedFileSrc({ key: f.key, type: 'pdf', preventDl: this.$_.get(f, 'preventDl', false) }))
        if (f.type === 'image') return this.$_.set(f, 'src', await getEmbedFileSrc({ key: f.key, type: 'image' }))
        if (f.type === 'upload' && f.uploadConfig) return f
        if (f.type === 'expiredResume') return f
        return null
      })
      this.mappedFiles = (await Promise.all(mappedFilesPromises)).filter((f) => f !== null)
    },

    confirmRemove (file) {
      this.$q.dialog({
        title: this.$t({ id: file.uploadConfig.deleteTitle }),
        message: this.$t({ id: file.uploadConfig.deleteMessage }),
        cancel: {
          label: this.$t({ id: 'prompt.cancel_button' }),
          flat: true,
          noCaps: true,
          icon: 'uil:times'
        },
        ok: {
          color: 'negative',
          label: this.$t({ id: 'prompt.delete_button' }),
          flat: true,
          noCaps: true,
          icon: 'uil:trash'
        }
      }).onOk(() => {
        const removed = {}
        this.$_.set(removed, `attrs.${file.uploadConfig.field}`, null)
        this.$_.set(removed, 'id', file.uploadConfig.id)
        this.$emit('removed', removed)
      })
    }
  }
}
</script>
